import React, { useEffect, useMemo, useState } from "react"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import PropTypes from "prop-types"
import ColumnsDef from "../../../../table/column"
import noImage from "../../../../../../resources/images/noimage.png"
import dayjs from "dayjs"
import "dayjs/locale/ja"
import "./databable.css"
import {
  ContentPaste as ContentPasteIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material"
dayjs.locale("ja")

const useStyles = makeStyles({
  root: {
    color: "inherit",
  },
  image: {
    width: "340px",
  },
})

const DialogDetailDetailContentDataTable = (props) => {
  const classes = useStyles()
  const columnDef = useMemo(() => ColumnsDef({}), [])
  const [copyText, setCopyText] = useState("リンクコピー")
  const [diagnosticPdfUrl, setDiagnosticPdfUrl] = useState()

  const onLinkCopy = (treeId) => {
    navigator.clipboard
      .writeText(
        window.location.host + window.location.pathname + "?tree_id=" + treeId
      )
      .then((res) => {
        setCopyText("コピーしました")
      })
      .catch((e) => {
        setCopyText("コピー失敗")
      })
  }

  const onSelectDiagnosticPdf = (e) => {
    setDiagnosticPdfUrl(e.target.value)
    window.open(e.target.value)
  }

  return (
    <Box id="dialog_detail_datatable" className={classes.root}>
      {props.data.photos &&
        props.data.photos.split(",").map((v) => {
          return (
            <Box key={v}>
              <img
                className={classes.image}
                src={`https://images-tokyo.s3.ap-northeast-1.amazonaws.com/${v}`}
              />
            </Box>
          )
        })}
      {props.data.diagnostic_pdfs && (
        <Box>
          <FormControl>
            <InputLabel>診断カルテを表示</InputLabel>
            <Select
              onChange={onSelectDiagnosticPdf}
              style={{ width: "200px" }}
              value={diagnosticPdfUrl}
            >
              {Object.entries(props.data.diagnostic_pdfs).map(([date, pdf]) => (
                <MenuItem key={date} value={pdf}>
                  {date === "2021-01-01" && "2021年度"}
                  {date !== "2021-01-01" &&
                    dayjs(date).format("YYYY年MM月DD日")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <table>
        {columnDef.flatMap((def) => {
          let d = props.data[def.field]
          //          console.log(d, def.field)
          if (!d) {
            return []
          }
          if (def.field === "photos") {
            return
          }
          if (def.field === "diagnostic_pdfs") {
            return
          }

          switch (def.field) {
            case "date":
              d = dayjs(d).format("YYYY年M月D日(ddd)")
              break
            case "is_management_plate":
            case "is_mytree":
              d = d === 1 ? "有り" : "無し"
              break
            default:
              break
          }

          return (
            <tr key={def.field}>
              <th>{def.headerName}</th>
              <td>
                {d}{" "}
                {def.field === "tree_id" ? (
                  <a
                    href="#"
                    onClick={() => onLinkCopy(d)}
                    style={{ fontSize: "11px", color: "green" }}
                  >
                    {copyText}
                  </a>
                ) : (
                  ""
                )}
              </td>
            </tr>
          )
        })}
      </table>
    </Box>
  )
}

DialogDetailDetailContentDataTable.propTypes = {
  data: PropTypes.any,
  sx: PropTypes.object,
}

export default DialogDetailDetailContentDataTable
