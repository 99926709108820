import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

export const LayerType = {
  Koku: "kouku",
  Gyoseikai: "gyouseikai",
  RoadGrid: "roadgrid",
  Route: "route",
  HighlightRoute: "highlight_route",
  Yudoin: "yudoin",
}

export const LayerName = {
  Koku: "工区界",
  Gyoseikai: "行政界",
  RoadGrid: "道路図郭",
  Route: "路線",
  HighlightRoute: "見どころ路線",
  Yudoin: "誘導員が必要な路線",
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
}

const MapControlLayerSelector = (props) => {
  return (
    <Box sx={{ ...styles.root, ...props.sx }}>
      <label>
        <input
          type="radio"
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(null)}
        />
        なし
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.Koku}
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(LayerType.Koku)}
        />
        {LayerName.Koku}
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.Gyoseikai}
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(LayerType.Gyoseikai)}
        />
        {LayerName.Gyoseikai}
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.RoadGrid}
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(LayerType.RoadGrid)}
        />
        {LayerName.RoadGrid}
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.Route}
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(LayerType.Route)}
        />
        {LayerName.Route}
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.HighlightRoute}
          name="layer-selector"
          onClick={(e) =>
            props.onChange && props.onChange(LayerType.HighlightRoute)
          }
        />
        {LayerName.HighlightRoute}
      </label>
      <label>
        <input
          type="radio"
          value={LayerType.Yudoin}
          name="layer-selector"
          onClick={(e) => props.onChange && props.onChange(LayerType.Yudoin)}
        />
        {LayerName.Yudoin}
      </label>
    </Box>
  )
}

MapControlLayerSelector.propTypes = {
  onChange: PropTypes.func,
  sx: PropTypes.object,
}

export default MapControlLayerSelector
