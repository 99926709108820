import React from "react"
import dayjs from "dayjs"
import {
  booleanFilterParams,
  distinctFilterParams,
  distinctShikuchosonFilterParams,
  teibokuFilterParams,
} from "../../../manager/filter"

const RootTableColumnDef = (options) => [
  {
    field: "tree_id",
    headerName: "樹木ID",
    filter: "agTextColumnFilter",
    sort: "asc",
    pinned: "left",
    map_label: true,
    cellRendererFramework: (params) => {
      return (
        <span
          style={{
            color: "#38389d",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {params.value}
        </span>
      )
    },
  },
  {
    field: "type_adjust",
    headerName: "樹木タイプ",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "name",
    headerName: "種名",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "variant",
    headerName: "品種",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "height",
    headerName: "樹高(m)",
    filter: "agNumberColumnFilter",
    map_label: true,
  },
  {
    field: "perimeter",
    headerName: "幹周(cm)",
    filter: "agNumberColumnFilter",
    map_label: true,
  },
  {
    field: "koboku_perimeter_rank_name",
    headerName: "高木幹周ランク",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "width",
    headerName: "枝張(m)",
    filter: "agNumberColumnFilter",
    map_label: true,
  },
  {
    field: "area_name",
    headerName: "地区名",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "route_name",
    headerName: "路線名(通称)",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "route",
    headerName: "路線番号",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "teiboku_name",
    headerName: "低木種名",
    filterParams: teibokuFilterParams,
    map_label: true,
  },
  {
    field: "hedge_length",
    headerName: "生垣延長(m)",
    filter: "agNumberColumnFilter",
    map_label: true,
  },
  {
    field: "hedge_count",
    headerName: "生垣本数(本)",
    filter: "agNumberColumnFilter",
    map_label: true,
  },
  {
    field: "photos",
    headerName: "写真",
    cellRenderer: "photoCellRenderer",
    filterParams: {
      values: (params) => {
        params.success(["写真なし", "写真あり"])
      },
    },
    map_label: true,
  },
  {
    field: "is_management_plate",
    headerName: "管理プレート",
    cellRenderer: (params) => {
      if (!params.value) {
        return
      }
      if (parseInt(params.value) === 1) {
        return "有り"
      }
    },
    filterParams: {
      values: (params) => {
        params.success(["無し", "有り"])
      },
    },
  },
  {
    field: "is_mytree",
    headerName: "マイツリー",
    cellRenderer: (params) => {
      if (!params.value) {
        return
      }
      if (parseInt(params.value) === 1) {
        return "有り"
      }
    },
    filterParams: {
      values: (params) => {
        params.success(["無し", "有り"])
      },
    },
    map_label: true,
  },
  {
    field: "diagnostic_pdfs",
    headerName: "診断カルテ",
    cellRenderer: "diagnosticPdfCellRenderer",
    filterParams: {
      values: (params) => {
        params.success(["PDFなし", "PDFあり"])
      },
    },
    map_label: true,
  },
  {
    field: "office",
    headerName: "事務所",
    filterParams: distinctFilterParams,
    map_label: true,
  },
  {
    field: "citycode",
    headerName: "市区町村コード",
    filterParams: distinctFilterParams,
    hide: true,
    map_label: true,
  },
  {
    field: "latitude",
    headerName: "緯度",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "longitude",
    headerName: "経度",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_1",
    headerName: "幹周1",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_2",
    headerName: "幹周2",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_3",
    headerName: "幹周3",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_4",
    headerName: "幹周4",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_5",
    headerName: "幹周5",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_6",
    headerName: "幹周6",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_7",
    headerName: "幹周7",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_8",
    headerName: "幹周8",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_9",
    headerName: "幹周9",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "perimeter_10",
    headerName: "幹周10",
    filter: "agNumberColumnFilter",
    hide: true,
  },
  {
    field: "kouboku_gaikan_shindan_rank",
    headerName: "外観診断ランク(高木)",
    filterParams: distinctFilterParams,
  },
  {
    field: "kouboku_kiki_shindan_rank",
    headerName: "機器診断ランク(高木)",
    filterParams: distinctFilterParams,
  },
  {
    field: "next_diagnosis_date",
    headerName: "次期診断時期",
    filterParams: distinctFilterParams,
  },
  {
    field: "remarks",
    headerName: "メモ",
    filter: "agTextColumnFilter",
    map_label: true,
  },
  {
    field: "is_dead",
    headerName: "枯損",
    cellRenderer: (params) => {
      if (!params.value) {
        return
      }
      if (parseInt(params.value) === 1) {
        return "枯れ"
      }
    },
    filterParams: {
      values: (params) => {
        params.success(["(空白)", "枯れ"])
      },
    },
    map_label: true,
  },
  {
    field: "modifications",
    headerName: "修正",
    filter: "agTextColumnFilter",
    hide: true,
  },
  {
    field: "carbon_storage_kg",
    headerName: "炭素貯蔵量（kg）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "carbon_absorption_kg",
    headerName: "炭素吸収量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "co2_absorption_kg",
    headerName: "CO₂吸収量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "co_absorption_kg",
    headerName: "CO吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "no2_adsorption_kg",
    headerName: "NO₂吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "o3_adsorption_kg",
    headerName: "O₃吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "pm2_5_adsorption_kg",
    headerName: "PM2.5吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "so2_adsorption_kg",
    headerName: "SO₂吸着量（kg/年）",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "tree_canopy_interception_m3",
    headerName: "樹冠遮断量（m3/年)",
    filter: "agNumberColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "flower_month_ja",
    headerName: "開花時期",
    filter: "agTextColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "fruit_month_ja",
    headerName: "果実時期",
    filter: "agTextColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "autumnleaves_month_ja",
    headerName: "紅葉時期",
    filter: "agTextColumnFilter",
    hide: true,
    map_label: true,
  },
  {
    field: "is_highlight_route",
    headerName: "見どころ路線",
    filterParams: booleanFilterParams,
    cellRenderer: (params) => {
      if (params.value === true) {
        return "☑"
      } else if (params.value === false) {
        return "□"
      } else {
        return null
      }
    },
    hide: false,
    map_label: true,
  },
  {
    field: "created_user_id",
    headerName: "最終更新者ID",
    filterParams: distinctFilterParams,
  },
  {
    field: "created_user_name",
    headerName: "最終更新者名",
    filterParams: distinctFilterParams,
  },
]

export default RootTableColumnDef
