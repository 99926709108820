import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Box, IconButton, Typography } from "@mui/material"
import {
  SkipNext as SkipNextIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
  SkipPrevious as SkipPreviosIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"

import { AgGridColumn, AgGridReact } from "ag-grid-react"
import LOCALE_JA from "../../../resources/aggrid/locale.ja"
import DefaultColumnDef from "./column"
import TablePhotoCellRenderer from "./render/photoCell"
import PropTypes from "prop-types"
import GairojuManager from "../../../manager/gairoju"
import dayjs from "dayjs"
import { RootDataContext } from "../index"
import TableDiagnosticPdfCellRenderer from "./render/diagnosticPdfCell"

const tableSavedStateKey = "root-table-state-key"

// スタイル定義
const useStyle = makeStyles({
  root: {
    height: "calc(100% - 30px) !important",
    color: "inherit",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    color: "#6c6c6c",
    fontSize: "12px",
    marginRight: "16px",
  },
  pageButton: {
    padding: "0 !important",
    margin: "0 !important",
    color: "#999",
  },
})

let _gridApi = null

const RootTableView = (props) => {
  // スタイル
  const classes = useStyle()
  // AgGridのGridAPI
  const [gridApi, setGridApi] = useState(null)
  // AgGridのColumnAPI
  const [columnApi, setColumnApi] = useState(null)

  const { state, setFilterData, setSortData, setColumnState, setSelectedData } =
    useContext(RootDataContext)

  const [startPageItemNumber, setStartPageItemNumber] = useState(0)
  const [endPageItemNumber, setEndPageItemNumber] = useState(0)
  const [totalPageItemNumber, setTotalPageItemNumber] = useState(0)
  const [currentPageNumber, setCurrentPageNumber] = useState(0)
  const [totalPageNumber, setTotalPageNumber] = useState(0)
  const [initColumnState, setInitColumnState] = useState(false)

  const [columnDef, setColumnDef] = useState(DefaultColumnDef({}))

  const totalCountRef = useRef()
  const [totalCount, setTotalCount] = useState()
  const mapSelectPolygonRef = useRef()
  const mapSelectRectangleRef = useRef()

  useEffect(() => {
    console.log(
      "[Table]",
      "update",
      state.mapSelectPolygon,
      state.mapSelectRectangle
    )
    mapSelectRectangleRef.current = state.mapSelectRectangle
    mapSelectPolygonRef.current = state.mapSelectPolygon
  }, [state.mapSelectPolygon, state.mapSelectRectangle])

  useEffect(() => {
    setInterval(() => {
      setTotalCount(totalCountRef.current)
    }, 100)
  }, [])

  useEffect(() => {
    if (!gridApi) {
      return
    }
    state.filterData && gridApi.setFilterModel(state.filterData)
    state.sortData && gridApi.setSortModel(state.sortData)
  }, [gridApi, state.filterData, state.sortData])

  useEffect(() => {
    if (!columnApi) {
      return
    }
    console.log("[AgGrid]", "column api column state effected")
    if (state.columnState) {
      if (!initColumnState) {
        columnApi.applyColumnState({
          state: state.columnState,
          applyOrder: true,
        })
        setInitColumnState(true)
      }
    } else {
      columnApi.resetColumnState()
      setInitColumnState(false)
    }
  }, [columnApi, state.columnState])

  useEffect(() => {
    if (!gridApi || !state.refreshTime) {
      return
    }
    console.log(gridApi)
    gridApi.refreshServerSideStore()
  }, [state.refreshTime, gridApi])

  const onGridReady = useCallback(
    (params) => {
      _gridApi = params.api

      setGridApi(params.api)
      setColumnApi(params.columnApi)

      params.api.setServerSideDatasource(dataSource)
    },
    [state.mapSelectPolygon, state.mapSelectRectangle]
  )

  const dataSource = useMemo(() => {
    return {
      getRows(params) {
        console.log(
          "[Table]",
          "getRows",
          mapSelectPolygonRef.current,
          mapSelectRectangleRef.current
        )
        totalCountRef.current = null
        GairojuManager.tableQuery(
          params,
          mapSelectPolygonRef.current,
          mapSelectRectangleRef.current
        ).then((rowCount) => {
          console.log(rowCount)
          //        setTotalPageItemNumber(rowCount)
          totalCountRef.current = rowCount
        })
      },
    }
  }, [state.mapSelectPolygon, state.mapSelectRectangle])
  //
  // const dataSource = {
  //   getRows(params) {
  //     console.log(
  //       "[Table]",
  //       "getRows",
  //       state.mapSelectPolygon,
  //       state.mapSelectRectangle
  //     )
  //     totalCountRef.current = null
  //     GairojuManager.tableQuery(
  //       params,
  //       state.mapSelectPolygon,
  //       state.mapSelectRectangle
  //     ).then((rowCount) => {
  //       console.log(rowCount)
  //       //        setTotalPageItemNumber(rowCount)
  //       totalCountRef.current = rowCount
  //     })
  //   },
  // }

  const onCellClicked = (e) => {}

  const onCellFocused = async (e) => {
    let row = gridApi.getDisplayedRowAtIndex(e.rowIndex)
    if (!row || !row.data || !Object.keys(row.data).includes(e.column.colId)) {
      return
    }
    let value = row.data[e.column.colId]

    // 同じ場所をクリックしたらクリアする
    if (
      state.selectData?.rowIndex === e.rowIndex &&
      state.selectData?.colId === e.column.colId
    ) {
      setSelectedData(null)
    } else {
      let colId = e.column.colId
      let displayValue = value

      switch (colId) {
        case "date":
          displayValue = dayjs(value).format("YYYY年MM月DD日")
          break
        case "tree_id":
          props.showDetailDialog(row.data)
          break
        case "diagnostic_pdfs":
          displayValue = "PDF有り"
          break
        default:
          break
      }

      setSelectedData({
        value,
        displayValue,
        colId,
        rowIndex: e.rowIndex,
        field: e.column.colDef.field,
      })
    }
  }

  const onFilterChanged = (e) => {
    setFilterData(gridApi.getFilterModel())
  }

  const onSortChanged = (e) => {
    setSortData(gridApi.getSortModel())
    onColumnStateChanged()
  }

  const onPaginationChanged = (e) => {
    if (gridApi) {
      setCurrentPageNumber(
        gridApi.paginationGetPageSize() === 0
          ? 0
          : gridApi.paginationGetCurrentPage() + 1
      )
      setStartPageItemNumber(
        gridApi.paginationGetPageSize() === 0
          ? 0
          : gridApi.paginationGetCurrentPage() *
              gridApi.paginationGetPageSize() +
              1
      )
      setEndPageItemNumber(
        gridApi.paginationGetPageSize() === 0
          ? 0
          : gridApi.paginationGetCurrentPage() *
              gridApi.paginationGetPageSize() +
              gridApi.paginationGetPageSize() >
            gridApi.paginationGetRowCount()
          ? gridApi.paginationGetRowCount()
          : gridApi.paginationGetCurrentPage() *
              gridApi.paginationGetPageSize() +
            gridApi.paginationGetPageSize()
      )
      setTotalPageItemNumber(gridApi.paginationGetRowCount())
      setTotalPageNumber(
        gridApi.paginationGetPageSize() === 0
          ? 0
          : Math.ceil(
              gridApi.paginationGetRowCount() / gridApi.paginationGetPageSize()
            )
      )
    }
  }

  const onFirstPage = () => {
    gridApi.paginationGoToFirstPage()
  }

  const onLastPage = () => {
    gridApi.paginationGoToLastPage()
  }

  const onNextPage = () => {
    gridApi.paginationGoToNextPage()
  }

  const onPrevPage = () => {
    gridApi.paginationGoToPreviousPage()
  }

  const onColumnStateChanged = (e) => {
    if (!columnApi) {
      return
    }
    setColumnState(columnApi.getColumnState())
  }

  return (
    <>
      <AgGridReact
        className={`${classes.root} ag-theme-balham`}
        onGridReady={onGridReady}
        onCellClicked={onCellClicked}
        onCellFocused={onCellFocused}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onColumnResized={onColumnStateChanged}
        onColumnVisible={onColumnStateChanged}
        onColumnRowGroupChanged={onColumnStateChanged}
        onColumnValueChanged={onColumnStateChanged}
        onColumnPinned={onColumnStateChanged}
        onColumnMoved={onColumnStateChanged}
        onColumnPivotChanged={onColumnStateChanged}
        onPaginationChanged={onPaginationChanged}
        rowModelType="serverSide"
        localeText={LOCALE_JA}
        overlayLoadingTemplate={
          '<span className="ag-overlay-loading-center">読込中...</span>'
        }
        serverSideStoreType="partial"
        rowSelection="multiple"
        enableRangeSelection={true}
        rowHeight={30}
        headerHeight={20}
        suppressRowClickSelection={true}
        suppressPaginationPanel={true}
        floatingFiltersHeight={20}
        defaultColDef={{
          resizable: true,
          sortable: true,
          filter: true,
          floatingFilter: true,
        }}
        columnDefs={columnDef}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "列選択",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          //          defaultToolPanel: "columns",
        }}
        frameworkComponents={{
          photoCellRenderer: TablePhotoCellRenderer,
          diagnosticPdfCellRenderer: TableDiagnosticPdfCellRenderer,
        }}
      ></AgGridReact>
      <Box style={{ height: "25px" }} className={classes.footer}>
        表示中の樹木本数：{totalCount?.toLocaleString() ?? "--"}本
      </Box>
    </>
  )
}

RootTableView.propTypes = {
  windowUuid: PropTypes.string,
  showDetailDialog: PropTypes.func,
}

export default RootTableView
