import React, { useMemo, useRef, useState } from "react"
import { makeStyles } from "@mui/styles"
import { GoogleMap, Marker, StreetViewPanorama } from "@react-google-maps/api"
import PropTypes from "prop-types"
import { point, bearing } from "turf/turf"

const styles = {
  root: {
    width: "100%",
    backgroundColor: "",
  },
  map: {
    width: "100%",
    height: "100%",
  },
  streetView: {
    width: "800px",
    height: "480px",
  },
}

const DialogDetailContentDetailStreetView = (props) => {
  const panoRef = useRef()

  const [position, setPosition] = useState()

  const center = useMemo(() => {
    return {
      lat: props.data.latitude ?? 35.6813706610132,
      lng: props.data.longitude ?? 139.76727819517552,
    }
  }, [props.data])

  const heading = useMemo(() => {
    if (!center || !position) {
      return
    }
    const from = point([position.lng, position.lat])
    const to = point([center.lng, center.lat])
    const angle = bearing(from, to)
    return (angle + 360) % 360
  }, [center, position])

  const onVisibleChanged = (e) => {
    console.log("[StreetView]", "visible changed", e)
  }

  const onPanoChanged = (e) => {
    console.log(
      "[StreetView]",
      "pano changed",
      panoRef.current?.getLocation().latLng.lng(),
      panoRef.current?.getLocation().latLng.lat(),
      panoRef.current?.getPosition().lng(),
      panoRef.current?.getPosition().lat()
      // panoRef.current?.getLocation().lng(),
      // panoRef.current?.getLocation().lat()
      // panoRef.current?.getPosition().lng(),
      // panoRef.current?.getPosition().lat()
    )
    setPosition({
      lng: panoRef.current?.getLocation().latLng.lng(),
      lat: panoRef.current?.getLocation().latLng.lat(),
    })
  }

  const onLoad = (e) => {
    panoRef.current = e
    console.log(
      "[StreetView]",
      "on load",
      e,
      e.getPosition().lng(),
      e.getPosition().lat(),
      e.getLocation(),
      e.getPov(),
      props.data
    )
  }

  return (
    <GoogleMap mapContainerStyle={styles.map} center={center} zoom={10}>
      <Marker position={center} />
      <StreetViewPanorama
        onLoad={onLoad}
        onVisibleChanged={onVisibleChanged}
        onPanoChanged={onPanoChanged}
        mapContainerStyle={styles.streetView}
        position={center}
        visible={true}
        options={{
          enableCloseButton: false,
          pov: {
            heading,
            pitch: 0,
          },
        }}
      />
    </GoogleMap>
  )
}

DialogDetailContentDetailStreetView.propTypes = {
  data: PropTypes.object,
}

export default DialogDetailContentDetailStreetView
